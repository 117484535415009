import { call, put } from "redux-saga/effects";
import { API_URL, ACTIONS } from "../_config";
import { CallApi } from "./api/callApi";

export function* getMenuList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.MENU_ITEMS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.COMMON.MENU_LIST,
        payload: resp.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getSettings(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.SETTINGS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.COMMON.SETTINGS,
        payload: resp.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getSponsor(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.SPONSOR, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.COMMON.SPONSOR,
        payload: resp.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getStatusMessage(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.STATUS_MESSAGE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getGallery(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GALLERY + "/" + data.gameId + "/" + data.type, null, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getCountries(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.COUNTRIES, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getStates(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.STATES + "/" + data.countryId + "/" + data.type, null, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getCities(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.CITIES + "/" + data.stateId, null, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getTestimonials(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.TESTIMONIALS, null, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getRiders(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.RIDERS, null, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getNewsList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.NEWS.LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getNewsDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.NEWS.DETAILS + "/" + data.slug, null, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getBlocks(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.BLOCKS + data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.COMMON.BLOCKS,
        payload: resp.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getWinnerStory(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.WINNERSTORY + (data?.slug || ""), data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* contactUs(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.CONTACTUS, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getSEOList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.SEO_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.COMMON.SEO_LIST,
        payload: resp.data.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
