import { SAGA_ACTIONS } from "src/_config";
// import { GetEventList } from "src/_common/interfaces/ApiReqRes";
import { useApiCall } from "../common/appApiCallHook";

export function useEventApi() {
  const callApi = useApiCall();

  const menuList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.MENU_ITEMS, data, onSuccess, onError);
  };
  const settings = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.SETTINGS, data, onSuccess, onError);
  };
  const gameList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GAME.GET_GAME_LIST, data, onSuccess, onError);
  };
  const teamList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.MY_TEAM_LIST, data, onSuccess, onError);
  };
  const leagueList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.LEAGUE.MY_LEAGUE_LIST, data, onSuccess, onError);
  };
  const sponsorList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.SPONSOR, data, onSuccess, onError);
  };
  const getDraftTeamData = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.LOAD_DRAFT_TEAM_DATA, data, onSuccess, onError);
  };
  const saveTempDataTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.SAVE_DRAFT_TEAM_DATA, data, onSuccess, onError);
  };
  const getEventList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.GET_EVENT_LIST, data, onSuccess, onError);
  };
  const getInsuranceList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.GET_INSURANCE_LIST, data, onSuccess, onError);
  };
  const getPrizeList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.GET_PRIZE_LIST, data, onSuccess, onError);
  };
  const getFrontendLeague = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.GET_FRONTEND_LEAGUE, data, onSuccess, onError);
  };
  const getStatusMessage = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.STATUS_MESSAGE, data, onSuccess, onError);
  };
  const getGallery = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GALLERY, data, onSuccess, onError);
  };
  const getCountries = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.COUNTRIES, data, onSuccess, onError);
  };
  const getStates = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.STATES, data, onSuccess, onError);
  };
  const getCities = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.CITIES, data, onSuccess, onError);
  };
  const newsletterSignup = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NEWSLETTER.SIGNUP, data, onSuccess, onError);
  };
  const gameEntryOpenNotification = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GAME.ENTRY_OPEN_NOTIFICATION, data, onSuccess, onError);
  };
  const upcomingGames = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GAME.UPCOMING_GAMES, null, onSuccess, onError);
  };
  const getNewsList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NEWS.LIST, data, onSuccess, onError);
  };
  const getNewsDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.NEWS.DETAILS, data, onSuccess, onError);
  };
  const getTestimonials = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.TESTIMONIALS, data, onSuccess, onError);
  };
  const getRiders = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.RIDERS, data, onSuccess, onError);
  };
  const getBlocksData = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.BLOCKS, data, onSuccess, onError);
  };
  const checkLeagueCode = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.LEAGUE.CHECK_LEAGUE_CODE, data, onSuccess, onError);
  };
  const createLeague = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.LEAGUE.CREATE_LEAGUE_CODE, data, onSuccess, onError);
  };
  const checkApplyPromoCode = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.CHECK_PROMO_CODE, data, onSuccess, onError);
  };
  const getLeagueDetailsByID = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.LEAGUE.LEAGUE_BY_LEAGUEID, data, onSuccess, onError);
  };
  const getWinnerStory = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.WINNERSTORY, data, onSuccess, onError);
  };
  const getContestantsAI = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.GET_CONTESTANT_AI, data, onSuccess, onError);
  };
  const contestantSearchAI = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.SEARCH_AI_SELECT_LIST, data, onSuccess, onError);
  };
  const checkContestantAI = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.CHECK_CONTESTANT_AI, data, onSuccess, onError);
  };
  const contactUs = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.CONTACTUS, data, onSuccess, onError);
  };
  const getCardList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.GET_CARD_LIST, data, onSuccess, onError);
  };
  const deleteCard = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.DELETE_CARD, data, onSuccess, onError);
  };
  const setDefaultCard = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.SET_DEFAULT_CARD, data, onSuccess, onError);
  };
  const makePayment = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.MAKE_PAYMENT, data, onSuccess, onError);
  };
  const makeBulkPayment = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.MAKE_BULK_PAYMENT, data, onSuccess, onError);
  };
  const deleteTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.DELETE_TEAM, data, onSuccess, onError);
  };
  const deleteLeague = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.LEAGUE.DELETE_LEAGUE, data, onSuccess, onError);
  };
  const getTeamLeagueList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.LEAGUE.TEAM_LIST, data, onSuccess, onError);
  };
  const searchTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.SEARCH_TEAM, data, onSuccess, onError, true);
  };
  const getTeamDataById = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.LOAD_TEAM_DETAILS, data, onSuccess, onError, true);
  };
  const updateTeam = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.UPDATE_TEAM, data, onSuccess, onError, true);
  };
  const getPerformanceList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.GET_PERFORMANCE_LIST, data, onSuccess, onError, true);
  };
  const getScoreDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.GET_SCORE_DETAILS, data, onSuccess, onError, true);
  };
  const getPickBbrScoreDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.GET_PICK_BBR_SCORE_DETAILS, data, onSuccess, onError, true);
  };
  const getInsuranceApplicable = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.GET_INSURANCE_APPLICABLE, data, onSuccess, onError, true);
  };
  const getInsuranceApply = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.INSURANCE_APPLY, data, onSuccess, onError, true);
  };
  const getRevertInsurance = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.REVERT_INSURANCE, data, onSuccess, onError, true);
  };
  const getRankDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.GET_RANK_DETAILS, data, onSuccess, onError, true);
  };
  const getScores = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.GET_GAME_SCORE, data, onSuccess, onError, true);
  };
  const getRodeoResults = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.GET_RODEO_RESULTS, data, onSuccess, onError, true);
  };
  const getRoundWiseResults = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.ROUNDWISERESULTS, data, onSuccess, onError, true);
  };
  const getCurcuitResults = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.CURCUITRESULTS, data, onSuccess, onError, true);
  };
  const getLatestStandings = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.LATESTSTANDINGS, data, onSuccess, onError, true);
  };
  const getLeagueOverallScoreResults = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.LEAGUEOVERALLSCORE, data, onSuccess, onError, true);
  };
  const getLeagueRoundScoreResults = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.LEAGUEROUNDSCORE, data, onSuccess, onError, true);
  }
  const getPrizeOverallScoreResults = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.PRIZEOVERALLSCORE, data, onSuccess, onError, true);
  }
  const getPrizeRoundScoreResults = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.PRIZEROUNDSCORE, data, onSuccess, onError, true);
  }
  const getTopHeaderScore = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SCORE.TOPHEADERSCORE, data, onSuccess, onError, true);
  }
  const getGameDetailsById = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GAME.GAMEDETAILSBYID, data, onSuccess, onError, true);
  }
  const getSEO = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.SEO_LIST, data, onSuccess, onError);
  };
  const autoLogin = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AUTOLOGIN, data, onSuccess, onError);
  };
  const getCartItems = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.TEAM.CART_ITEMS, data, onSuccess, onError);
  };
  const checkGameStatus = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.GAME.CHECKGAMESTATUS, data, onSuccess, onError);
  };
  return {
    callGetMenuList: menuList,
    callSettings: settings,
    callGetGameList: gameList,
    callGetTeamDataById: getTeamDataById,
    callGetSponsorList: sponsorList,
    callLoadDraftTeamData: getDraftTeamData,
    callSaveDraftTeamData: saveTempDataTeam,
    callEventList: getEventList,
    callInsuranceListData: getInsuranceList,
    callPrizeListData: getPrizeList,
    callFrontendLeagueData: getFrontendLeague,
    callGetStatusMessage: getStatusMessage,
    callGetGallery: getGallery,
    callGetCountries: getCountries,
    callGetStates: getStates,
    callGetCities: getCities,
    callNewsletterSignup: newsletterSignup,
    callBlocksData: getBlocksData,
    callGameEntryOpenNotification: gameEntryOpenNotification,
    callUpcomingGames: upcomingGames,
    callCheckLeagueCode: checkLeagueCode,
    callCreateLeague: createLeague,
    callGetNewsList: getNewsList,
    callGetNewsDetails: getNewsDetails,
    callGetTestimonials: getTestimonials,
    callGetRiders: getRiders,
    callCheckApplyPromoCode: checkApplyPromoCode,
    callLeagueDetailsByID: getLeagueDetailsByID,
    callGetWinnerStory: getWinnerStory,
    callContestantsAI: getContestantsAI,
    callContestantSearchAI: contestantSearchAI,
    callcheckContestantAI: checkContestantAI,
    callContactUs: contactUs,
    callGetCardList: getCardList,
    callDeleteCard: deleteCard,
    callMakePayment: makePayment,
    callUpdateTeam: updateTeam,
    callTeamList: teamList,
    callLeagueList: leagueList,
    callDeleteTeam: deleteTeam,
    callDeleteLeague: deleteLeague,
    callSetDefaultCard: setDefaultCard,
    callGetTeamLeagueList: getTeamLeagueList,
    callSearchTeam: searchTeam,
    callPerformanceList: getPerformanceList,
    callScoreDetails: getScoreDetails,
    callInsuranceApplicable: getInsuranceApplicable,
    callInsuranceApply: getInsuranceApply,
    callRevertInsurance: getRevertInsurance,
    callRankDetails: getRankDetails,
    callGetScores: getScores,
    callRodeoResults: getRodeoResults,
    callRoundWiseResults: getRoundWiseResults,
    callCurcuitResults: getCurcuitResults,
    callLatestStandings: getLatestStandings,
    callLeagueOverallScoreResults: getLeagueOverallScoreResults,
    callLeagueRoundScoreResults: getLeagueRoundScoreResults,
    callPrizeOverallScoreResults: getPrizeOverallScoreResults,
    callPrizeRoundScoreResults: getPrizeRoundScoreResults,
    callGetTopHeaderScore: getTopHeaderScore,
    callGetGameDetails: getGameDetailsById,
    callSEO: getSEO,
    callPickBbrScoreDetails: getPickBbrScoreDetails,
    callAutoLogin: autoLogin,
    callCartItems: getCartItems,
    callMakeBulkPayment: makeBulkPayment,
    callCheckGameStatus: checkGameStatus
  };
}
