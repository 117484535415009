import { takeLatest, all } from "redux-saga/effects";
import { SAGA_ACTIONS } from "../_config";

import {
  forgotPassword,
  getUserProfile,
  loginUser,
  loginUserWithUsername,
  loginUserWithOtp,
  logoutUser,
  updateUserProfile,
  getCardList,
  deleteCard,
  setDefaultCard,
  deleteUser,
  AutoLogin,
} from "./userSaga";

import {
  getCities,
  getCountries,
  getGallery,
  getMenuList,
  getNewsDetails,
  getNewsList,
  getRiders,
  getSettings,
  getSponsor,
  getStates,
  getStatusMessage,
  getTestimonials,
  getBlocks,
  getWinnerStory,
  contactUs,
  getSEOList,
} from "./commonSaga";

import { gameEntryOpenNotification, getGameList, upcomingGames, getGameDetailsById, checkGameStatus } from "./gameSaga";

import {
  checkLeagueCode,
  createLeagueCode,
  getLeagueDetailsByID,
  getTeamLeagueList,
  callMyLeagueList,
  deleteLeague,
} from "./leagueSaga";

import {
  getTeamDraftData,
  saveTeamDraftData,
  getEventList,
  getInsuranceList,
  getPrizeList,
  getFrontendCharity,
  checkApplyPromoCode,
  getContestantAILIst,
  getSearchContestantAILIst,
  getCheckContestantAI,
  callMakePayment,
  callMyTeamList,
  searchTeam,
  deleteTeam,
  loadTeamDataByID,
  callUpdateTeam,
  getPerformanceList,
  getInsuranceApplicable,
  getInsuranceApply,
  getRevertInsurance,
  getCartItems,
  callBulkMakePayment,
} from "./teamSaga";

import { getCmsContent } from "./cmsSaga";

import { newsletterSignup } from "./newsletterSaga";

import {
  getScoreDetails,
  getRankDetails,
  getScore,
  getRodeoResults,
  getRoundWiseResults,
  getCurcuitResults,
  getLatestStandings,
  getLeagueOverallScoreResults,
  getLeagueRoundScoreResults,
  getPrizeOverallScoreResults,
  getPrizeRoundScoreResults,
  getTopHeaderScore,
  getPickBbrScoreDetails,
} from "./scoreSaga";

export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.DETAILS, getUserProfile),
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.AUTOLOGIN, AutoLogin),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_OTP, loginUserWithOtp),
    takeLatest(SAGA_ACTIONS.USER.LOGIN_WITH_USERNAME, loginUserWithUsername),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateUserProfile),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, logoutUser),
    takeLatest(SAGA_ACTIONS.USER.DELETE_USER, deleteUser),

    //COMMON Saga
    takeLatest(SAGA_ACTIONS.COMMON.MENU_ITEMS, getMenuList),
    takeLatest(SAGA_ACTIONS.COMMON.SETTINGS, getSettings),
    takeLatest(SAGA_ACTIONS.COMMON.SPONSOR, getSponsor),
    takeLatest(SAGA_ACTIONS.COMMON.STATUS_MESSAGE, getStatusMessage),
    takeLatest(SAGA_ACTIONS.COMMON.GALLERY, getGallery),
    takeLatest(SAGA_ACTIONS.COMMON.COUNTRIES, getCountries),
    takeLatest(SAGA_ACTIONS.COMMON.STATES, getStates),
    takeLatest(SAGA_ACTIONS.COMMON.CITIES, getCities),
    takeLatest(SAGA_ACTIONS.COMMON.TESTIMONIALS, getTestimonials),
    takeLatest(SAGA_ACTIONS.COMMON.RIDERS, getRiders),
    takeLatest(SAGA_ACTIONS.NEWS.LIST, getNewsList),
    takeLatest(SAGA_ACTIONS.NEWS.DETAILS, getNewsDetails),
    takeLatest(SAGA_ACTIONS.COMMON.BLOCKS, getBlocks),
    takeLatest(SAGA_ACTIONS.COMMON.WINNERSTORY, getWinnerStory),
    takeLatest(SAGA_ACTIONS.COMMON.CONTACTUS, contactUs),
    takeLatest(SAGA_ACTIONS.COMMON.SEO_LIST, getSEOList),

    //GAME Saga
    takeLatest(SAGA_ACTIONS.GAME.GET_GAME_LIST, getGameList),
    takeLatest(SAGA_ACTIONS.GAME.ENTRY_OPEN_NOTIFICATION, gameEntryOpenNotification),
    takeLatest(SAGA_ACTIONS.GAME.UPCOMING_GAMES, upcomingGames),
    takeLatest(SAGA_ACTIONS.GAME.GAMEDETAILSBYID, getGameDetailsById),
    takeLatest(SAGA_ACTIONS.GAME.CHECKGAMESTATUS, checkGameStatus),

    //League Saga
    takeLatest(SAGA_ACTIONS.LEAGUE.CHECK_LEAGUE_CODE, checkLeagueCode),
    takeLatest(SAGA_ACTIONS.LEAGUE.CREATE_LEAGUE_CODE, createLeagueCode),
    takeLatest(SAGA_ACTIONS.LEAGUE.LEAGUE_BY_LEAGUEID, getLeagueDetailsByID),
    takeLatest(SAGA_ACTIONS.LEAGUE.MY_LEAGUE_LIST, callMyLeagueList),
    takeLatest(SAGA_ACTIONS.LEAGUE.TEAM_LIST, getTeamLeagueList),
    takeLatest(SAGA_ACTIONS.LEAGUE.DELETE_LEAGUE, deleteLeague),

    //TEAM Saga
    takeLatest(SAGA_ACTIONS.TEAM.LOAD_DRAFT_TEAM_DATA, getTeamDraftData),
    takeLatest(SAGA_ACTIONS.TEAM.SAVE_DRAFT_TEAM_DATA, saveTeamDraftData),
    takeLatest(SAGA_ACTIONS.TEAM.GET_EVENT_LIST, getEventList),
    takeLatest(SAGA_ACTIONS.TEAM.GET_INSURANCE_LIST, getInsuranceList),
    takeLatest(SAGA_ACTIONS.TEAM.GET_PRIZE_LIST, getPrizeList),
    takeLatest(SAGA_ACTIONS.TEAM.GET_FRONTEND_LEAGUE, getFrontendCharity),
    takeLatest(SAGA_ACTIONS.TEAM.CHECK_PROMO_CODE, checkApplyPromoCode),
    takeLatest(SAGA_ACTIONS.TEAM.GET_CONTESTANT_AI, getContestantAILIst),
    takeLatest(SAGA_ACTIONS.TEAM.SEARCH_AI_SELECT_LIST, getSearchContestantAILIst),
    takeLatest(SAGA_ACTIONS.TEAM.CHECK_CONTESTANT_AI, getCheckContestantAI),
    takeLatest(SAGA_ACTIONS.TEAM.MY_TEAM_LIST, callMyTeamList),
    takeLatest(SAGA_ACTIONS.TEAM.SEARCH_TEAM, searchTeam),
    takeLatest(SAGA_ACTIONS.TEAM.DELETE_TEAM, deleteTeam),
    takeLatest(SAGA_ACTIONS.TEAM.LOAD_TEAM_DETAILS, loadTeamDataByID),
    takeLatest(SAGA_ACTIONS.TEAM.UPDATE_TEAM, callUpdateTeam),
    takeLatest(SAGA_ACTIONS.TEAM.GET_PERFORMANCE_LIST, getPerformanceList),
    takeLatest(SAGA_ACTIONS.TEAM.GET_INSURANCE_APPLICABLE, getInsuranceApplicable),
    takeLatest(SAGA_ACTIONS.TEAM.INSURANCE_APPLY, getInsuranceApply),
    takeLatest(SAGA_ACTIONS.TEAM.REVERT_INSURANCE, getRevertInsurance),
    takeLatest(SAGA_ACTIONS.TEAM.CART_ITEMS, getCartItems),
    //CMS Saga
    takeLatest(SAGA_ACTIONS.CMS.GET_CMS, getCmsContent),

    //Newsletter Saga
    takeLatest(SAGA_ACTIONS.NEWSLETTER.SIGNUP, newsletterSignup),

    //Score Saga
    takeLatest(SAGA_ACTIONS.SCORE.GET_SCORE_DETAILS, getScoreDetails),
    takeLatest(SAGA_ACTIONS.SCORE.GET_PICK_BBR_SCORE_DETAILS, getPickBbrScoreDetails),
    takeLatest(SAGA_ACTIONS.SCORE.GET_RANK_DETAILS, getRankDetails),
    takeLatest(SAGA_ACTIONS.SCORE.GET_GAME_SCORE, getScore),
    takeLatest(SAGA_ACTIONS.SCORE.GET_RODEO_RESULTS, getRodeoResults),
    takeLatest(SAGA_ACTIONS.SCORE.ROUNDWISERESULTS, getRoundWiseResults),
    takeLatest(SAGA_ACTIONS.SCORE.CURCUITRESULTS, getCurcuitResults),
    takeLatest(SAGA_ACTIONS.SCORE.LATESTSTANDINGS, getLatestStandings),
    takeLatest(SAGA_ACTIONS.SCORE.LEAGUEOVERALLSCORE, getLeagueOverallScoreResults),
    takeLatest(SAGA_ACTIONS.SCORE.LEAGUEROUNDSCORE, getLeagueRoundScoreResults),
    takeLatest(SAGA_ACTIONS.SCORE.PRIZEOVERALLSCORE, getPrizeOverallScoreResults),
    takeLatest(SAGA_ACTIONS.SCORE.PRIZEROUNDSCORE, getPrizeRoundScoreResults),
    takeLatest(SAGA_ACTIONS.SCORE.TOPHEADERSCORE, getTopHeaderScore),

    //Payment Saga
    takeLatest(SAGA_ACTIONS.PAYMENT.GET_CARD_LIST, getCardList),
    takeLatest(SAGA_ACTIONS.PAYMENT.DELETE_CARD, deleteCard),
    takeLatest(SAGA_ACTIONS.PAYMENT.SET_DEFAULT_CARD, setDefaultCard),
    takeLatest(SAGA_ACTIONS.PAYMENT.MAKE_PAYMENT, callMakePayment),
    takeLatest(SAGA_ACTIONS.PAYMENT.MAKE_BULK_PAYMENT, callBulkMakePayment),
  ]);
}
