import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { URLS } from "src/_config";
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import { useSponsorSelector } from 'src/_common/hooks/selectors/eventSelector';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Sponsor() {
    const toast = useToaster();
    const eventApi = useEventApi()
    const sponsorList = useSponsorSelector()
    useEffect(() => {
        getSponsorList()
    }, [])



    //Get Menu List
    const getSponsorList = () => {
        var params = {}

        eventApi.callGetSponsorList(params, (message: string, resp: any) => {

        }, (message: string) => {
            toast.error(message)
        })
    }

    return (
        <React.Fragment>
            {sponsorList && sponsorList.length > 0 &&
                <div className="white-box">
                    <div className="sponsor-panel">
                        <div className="d-flex justify-content-between align-items-center heading-block for-desktop">
                            <h2 className="section-heading">SPONSORS</h2>
                        </div>
                        <div className="sponsor-logo-wrapper">

                            <OwlCarousel
                                className="owl-carousel sponsors-carousel owl-theme"
                                items={5}
                                loop={true}
                                margin={50}
                                nav={true}
                                autoplay={true}
                                autoplayTimeout={3000}
                                autoplayHoverPause={true}
                                responsiveClass={true}
                                responsive={
                                    {
                                        0: {
                                            items: 2,
                                            nav: true
                                        },
                                        600: {
                                            items: 3,
                                            nav: false
                                        },
                                        1000: {
                                            items: 5,
                                            nav: true,
                                            margin: 50
                                        }
                                    }
                                }
                            >
                                {sponsorList.map((v: any, i: number) => {
                                    return (<div className="item" key={i}>
                                        {v.sponsorLink ? <a href={v.sponsorLink} target='_blank'> <img src={v.avatar.thumb} alt={"sponsor" + i} /></a> : <img src={v.avatar.thumb} alt={"sponsor" + i} />}
                                    </div>)
                                })}
                            </OwlCarousel>


                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Sponsor
