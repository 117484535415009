import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { URLS } from "src/_config";
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import { useCartItems } from 'src/_common/hooks/selectors/teamSelector';
import { useAppEventAction } from "src/_common/hooks/actions/event/appEventActionHook";
import { Modal, ModalBody } from "reactstrap";
import { useToaster } from "src/_common/hooks/actions/common/appToasterHook";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import moment from "moment";
import InputMask from "react-input-mask";

interface CartModalProps {
  onClose: (success: any) => void;
  shouldShow: boolean;
}

export default function CartModal({ onClose, shouldShow }: CartModalProps) {
  const hideplan = {
    display: 'none'
  };
  const showplan = {
    display: 'block'
  };
  const options = {
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  }
  const toast = useToaster();
  //Call Redux Value
  const eventAction = useAppEventAction();
  const cardItemsList = useCartItems()
  //Call API Hook
  const eventApi = useEventApi()
  const history = useHistory();
  const location = useLocation();
  const [payableTeam, setPayableTeam] = useState<any>({});
  const [cartPayable, setCartPayable] = useState<any>("");
  const [cartGameId, setCartGameId] = useState<number>(0);
  const [paymentOpenModal, setPaymentOpenModal] = useState<boolean>(false);
  const [cardList, setCardList] = useState<any>();
  const [cardId, setCardId] = useState<number>(0);
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardExpMonth, setCardExpMonth] = useState<string>("");
  const [cardExpYear, setCardExpYear] = useState<string>("");
  const [cardCvc, setCardCvc] = useState<string>("");
  const [cardName, setCardName] = useState<string>("");
  const [saveCardInfo, setSaveCardInfo] = useState<boolean>(false);
  const [teamSuccessModal, setTeamSuccessModal] = useState<boolean>(false);
  const [isNewCard, setIsNewCard] = useState<boolean>(false);
  const [TeamLoader, setTeamLoader] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<any>(null);
  const [showPayModal, setShowPayModal] = useState<boolean>(false);
  const [payLaterModal, setPayLaterModal] = useState<boolean>(false);
  const [isTeamEntryClosed, setIsTeamEntryClosed] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteTeamId, setDeleteTeamId] = useState<number>(0);
  useEffect(() => {
    getCartItems()
  }, [])

  const deleteTeamHandler = (teamId: number, gameId: number) => {
    if ($("input:checkbox[id=cartteam" + teamId + "]").is(":checked")) {
      toast.error("Please unckeck the Team first");
    } else {
      eventApi.callCheckGameStatus(gameId, (message: string, resp: any) => {
        if (resp.data) {
          setDeletePopup(true)
          setDeleteTeamId(teamId)
        }
        else {
          toast.error("Game entry closed. Please contact administrator for more details.")
          $('#cartteam' + teamId).prop('checked', false);
        }
      }, (message: string) => {
        toast.error(message)
      })
    }
  }

  const confirmTeamDelete = (teamId: number) => {
    eventAction.clearMyTeamList()
    eventApi.callDeleteTeam(teamId, (message: string, resp: any) => {
      toast.success("Team Deleted successfully");
      getCartItems(cartGameId)
    }, (message: string) => {
      toast.error(message)
    })
  }

  const getCartItems = (gameId?: any) => {
    let pname = 0
    if (gameId) {
      setCartGameId(gameId)
    }
    else {
      setCartGameId(0)
    }
    history.listen((location: any, action: any) => {
      if (location.pathname == '/build-team')
        pname = 1
    });
    if (location.pathname == '/build-team')
      pname = 1
    Promise.all([
      eventApi.callCartItems(
        gameId ? parseInt(gameId) : 0,
        (message: string, resp: any) => {
          $('input:checkbox[name=cartteam]').prop('checked', false);
          setShowPayModal(false)
          setCartPayable(0)
          setPayableTeam({})
          if (pname)
            setIsTeamEntryClosed(false)
          else
            setIsTeamEntryClosed(resp.add_team)
        },
        (message: string) => {
          toast.error(message);
        }
      ),
      eventApi.callGetCardList(
        {},
        (message: string, resp: any) => {
          if (resp.data.length == 0) {
            setIsNewCard(true);
          } else {
            //let cardid = resp.data[resp.data.length - 1].id;
            setIsNewCard(false);
            //setDefaultCardId(cardid);
            setCardList(resp);
            resp && resp.data
              .filter((x: any) => x.is_default == 1)
              .map((v: any) => {
                setCardId(v.id)
              });

          }
        },
        (message: string) => {
          toast.error(message);
        }
      )
    ]).then((_) => {

    });
  }

  const selectCartItems = (teamId: number, payable: any, gameId: number) => {
    eventApi.callCheckGameStatus(gameId, (message: string, resp: any) => {
      if (resp.data) {
        if ($("input:checkbox[id=cartteam" + teamId + "]").is(":checked")) {
          payableTeam[teamId] = payable
          setPayableTeam(payableTeam)
        } else {
          delete payableTeam[teamId]
          setPayableTeam(payableTeam)
        }
        console.log(payableTeam, Object.keys(payableTeam).length)
        if ((Object.keys(payableTeam).length) > 0)
          setShowPayModal(true)
        else
          setShowPayModal(false)
        let sum = 0;
        for (let el in payableTeam) {
          if (payableTeam.hasOwnProperty(el)) {
            sum += parseFloat(payableTeam[el]);
          }
        }
        setCartPayable(sum.toFixed(2))
      }
      else {
        toast.error("Game entry closed. Please contact administrator for more details.")
        $('#cartteam' + teamId).prop('checked', false);
      }
    }, (message: string) => {
      toast.error(message)
    })

  }

  const makeCartTeamPayment = () => {
    let ccCardNumber = cardNumber.replace(/[_-]/g, "");
    let cardMonth = parseInt(cardExpMonth.replace(/_/g, ""));
    let cardYear = parseInt(cardExpYear.replace(/_/g, ""));
    let cardCvv = parseInt(cardCvc.replace(/_/g, ""));
    if (!cardName.trim() && cardId == 0) {
      toast.error("Please Enter Name");
    } else if (!ccCardNumber && cardId == 0) {
      toast.error("Please Enter Card Number");
    } else if ((ccCardNumber.length > 16 || ccCardNumber.length < 15) && cardId == 0) {
      toast.error("Please Enter Valid Card Number");
    } else if (!cardExpMonth && cardId == 0) {
      toast.error("Please select Expiry Month");
    } else if ((cardMonth > 12 || cardMonth < 1) && cardId == 0) {
      toast.error("Please Enter Valid Expiry Month");
    } else if (!cardExpYear && cardId == 0) {
      toast.error("Please Enter Expiry Year");
    } else if (cardYear.toString().length != 4 && cardId == 0) {
      toast.error("Please Enter Valid Expiry Year");
    } else if (!cardCvc && cardId == 0) {
      toast.error("Please Enter CVV Number");
    } else if ((cardCvv.toString().length < 3 || cardCvv.toString().length > 4) && cardId == 0) {
      toast.error("Please Enter Valid Cvv Number");
    } else {
      let params = {
        card_name: cardName,
        card_number: cardNumber,
        card_exp_month: cardMonth,
        card_exp_year: cardYear ? cardYear : null,
        card_cvc: cardCvv,
        save_card_info: saveCardInfo,
        card_id: cardId,
        team_ids: Object.keys(payableTeam),
        payable_amount: cartPayable,
        pay_later: 0,
        is_team_draft: 0,
      }
      makePayment(params)
    }
  }

  const makePayment = (params: any) => {

    setTeamLoader(true)
    eventApi.callMakeBulkPayment(
      params,
      (message: string, resp: any) => {
        toast.success("Selected team(s) created successfully.");
        if (resp?.payData?.payment_response?.transactionid)
          setTransactionId(resp.payData.payment_response.transactionid)
        setTeamSuccessModal(true)
        setTeamLoader(false)
        eventAction.IsTeamCreateProcess(false)
      },
      (message: string) => {
        setPayLaterModal(true)
        toast.error(message);
      }
    )
  }

  const setIsSave = () => {
    if ($("input:checkbox[name=save_card_info]").is(":checked")) {
      setSaveCardInfo(true);
    } else {
      setSaveCardInfo(false);
    }
  }

  const newCardBlock = (val: any) => {
    if (val != 0) {
      setIsNewCard(false)
      setCardId(val)
    } else {
      setIsNewCard(true)
      setCardId(0)
    }
  }

  const preparePayment = () => {

    if (cartPayable < 1) {
      let params = {
        card_id: 0,
        team_ids: Object.keys(payableTeam),
        payable_amount: cartPayable,
        pay_later: 0,
        is_team_draft: 0,
      }
      makePayment(params)
    } else {
      setPaymentOpenModal(true)
    }
  }

  const saveCartPayLater = (type: number) => {

    setTimeout(() => {
      let paymentData = {
        card_id: 0,
        team_ids: Object.keys(payableTeam),
        payable_amount: cartPayable,
        pay_later: 0,
        is_team_draft: 0,
      }
      if (type == 1) {
        paymentData.pay_later = 1
      }
      else if (type == 2) {
        paymentData.is_team_draft = 1
      }
      makePayment(paymentData)
    }, 1000);
  }

  const completeAction = () => {
    setTeamSuccessModal(false)
    setTeamLoader(false)
    setPayLaterModal(false)
    setPayLaterModal(false)
    setPaymentOpenModal(false)
    setCartPayable(0)
    setPayableTeam({})
    setShowPayModal(false)
    getCartItems()
  }

  const goToAddTeam = () => {
    onClose(false)
    history.push(URLS.CREATETEAMLNK)
  }

  return (
    <React.Fragment>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={"insights-modal"}
        isOpen={shouldShow}
      >
        <div className="cross-icon" onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <ModalBody className={"m-18"}>
          <div className={deletePopup ? "white-box opa2" : "white-box position-relative"}>
            <div className="d-flex align-items-start heading-block gap-5">
              <div className="floating-cart floating-cart-relative">
                <span className="cart-badge">{cardItemsList?.my_teams?.length}</span>
                <i className="fa fa-users cart-grp-icn"></i>
              </div>
              <div className="cart-mdoal-heading">
                <h2 className="section-heading">Your team cart</h2>
                <h4>Here are the teams you've added to your cart.Feel free to <br /> remove any team you no longer wish to include.</h4>
              </div>
              {isTeamEntryClosed ? <div onClick={(e) => goToAddTeam()} className="red-btn d-flex justify-content-center align-items-center gap-2 linkcls mt-0">Add New Team +</div> : null}

            </div>

            {cardItemsList && cardItemsList.my_teams.length == 0 ?
              <div className="text-center">
                <div className="dashboard-no-data-block">
                  <img src="/assets/images/game-icon.svg" alt="" />
                  <h3>No teams yet added in cart.</h3>
                </div>
              </div> :
              <div className="dashboard-teams-block-wrapper">
                {cardItemsList?.my_teams?.map((k: any, j: number) => {
                  let curDate = moment(cardItemsList?.current_date_time).format('X')
                  let lastPerfmDate = moment(k.game_last_performance).format('X')

                  return (<div className="dashboard-teams-block d-flex" key={j} >
                    <div className="dashboard-teams-logo position-relative">
                      <img src={k.game_info.avatar.original} alt="" />
                      <div className="pay-checkbox"><input type="checkbox" className="form-check-input" name="cartteam" id={"cartteam" + k.id} onClick={(e) => selectCartItems(k.id, k.payable, k.game_id)} disabled={(k.game_info.gm_settings.is_entry_closed == 1) || (curDate > lastPerfmDate) ? true : false} /></div>
                    </div>
                    <div className="dashboard-teams-content flex-grow-1">
                      <div className="dashboard-teams-right-btns d-flex gap-4">
                        <span className="entries-closed-text text-red">${k.payable}</span>
                        <a className="d-flex align-items-center gap-2 delete-btn linkcls" onClick={(e) => deleteTeamHandler(k.id, k.game_id)}><i className="delete-icon"></i></a>
                      </div>
                      <div className="dashboard-teams-heading d-flex align-items-start gap-3">
                        {k.status == 1 ? <NavLink exact to={'/team/details/' + k.team_slug}>
                          <h2>{k.team_name} - {k.game_info.title}</h2></NavLink> : <h2>{k.team_name} - {k.game_info.title}</h2>}


                      </div>
                      <div className="dashboard-teams-sub-head">
                        <h3>Riders ({k.cboy_info ? k.cboy_info.length : 0})</h3>
                      </div>
                      <div className="dashboard-rider-block-wrapper d-flex gap-3 overflow-hidden">
                        {k.cboy_info && k.cboy_info.length ?
                          <OwlCarousel
                            className="owl-carousel riders-carousel owl-theme"
                            items={4}
                            loop={false}
                            margin={16}
                            nav={true}
                            autoplay={false}
                            autoplayTimeout={3000}
                            autoplayHoverPause={true}
                            responsiveClass={true}
                            responsive={
                              {
                                0: {
                                  items: 2,
                                  nav: true
                                },
                                600: {
                                  items: 3,
                                  nav: false
                                },
                                1000: {
                                  items: 4,
                                  nav: true,
                                  margin: 16
                                }
                              }
                            }
                          >
                            {k.cboy_info.map((p: any, i: number) => {
                              return (<div className="riders-list-row d-flex align-items-center" key={i}>
                                <div className="rider-list-avatar">
                                  {p?.c_info?.avatar?.original && <img src={p.c_info.avatar.original} alt="" />}
                                </div>
                                <div>
                                  <h4>{p?.c_info?.name}</h4>
                                  <p>{p?.e_info.title}</p>
                                </div>
                              </div>)
                            })}
                          </OwlCarousel>
                          : null}
                      </div>
                    </div>
                  </div>
                  )
                })}

              </div>
            }
            <div className="d-flex gap-3 align-items-center right-btns-select-fld">
              {showPayModal ?
                <div className=" gap-2 w-100">
                  <div className="amt-total-panel">
                    <h4 className="d-flex justify-content-between align-items-center gap-2"><strong>Amount Payable:</strong> <span>${cartPayable}</span></h4>
                  </div>
                  <button
                    onClick={(e) => preparePayment()}
                    className="red-btn w-100 text-uppercase justify-content-center align-items-center gap-2 w-100"
                  >
                    {cartPayable > 0 ? "Pay Now" : "Register Teams"} <i className="fa fa-check" />
                  </button>

                </div> : null
              }


            </div>
          </div>
          <div className={deletePopup ? "confirm-delete-box position-absolute" : "d-none"}>
            <h1>Are you sure want to delete your Team?</h1>
            <h3>If yes, you are not getting any refund of your payment.</h3>
            <button className="dark-button" onClick={() => setDeletePopup(false)}>No</button>
            <button className="red-button" onClick={() => {
              confirmTeamDelete(deleteTeamId)
              setDeletePopup(false)
            }}>Yes</button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={paymentOpenModal} size="xs" aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="cross-icon" onClick={(e) => setPaymentOpenModal(false)}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <ModalBody className={"text-center image-full"}>
          <div className="modal-body">
            <div className="payment-modal">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img src="/images/dollar-sign.svg" alt="" />
                <h2>Make payment</h2>
                <p>Please enter your card details below</p>
              </div>
              <div className="mb-2">
                <select className="form-control" id="sel1" onChange={(e) => newCardBlock(e.target.value)}>
                  <option value="0">New Card</option>
                  {cardList?.data?.map((v: any, i: number) => {
                    return (
                      <option key={i} value={v.id} selected={v.is_default}>
                        {v.card_number}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div style={isNewCard ? showplan : hideplan}>
                <div className="mb-2">
                  <input
                    placeholder="Full name (on the card)"
                    name="card_name"
                    type="text"
                    className="form-control"
                    autoComplete="cc-name"
                    onChange={(e) => setCardName(e.target.value)}
                  />
                </div>
                <div className="mb-2">
                  <InputMask
                    mask="9999-9999-9999-9999"
                    inputMode="numeric"
                    onChange={(e) => setCardNumber(e.target.value)}
                  >
                    {(inputProps: any) => (
                      <input {...inputProps} placeholder="9999-9999-9999-9999" type="text" className="form-control" autoComplete="cc-number" />
                    )}
                  </InputMask>
                </div>
                <div className="mb-2 d-flex gap-3">
                  <InputMask mask="99" inputMode="numeric" onChange={(e) => setCardExpMonth(e.target.value)}>
                    {(inputProps: any) => (
                      <input {...inputProps} placeholder="MM" type="text" className="form-control" autoComplete="cc-exp" />
                    )}
                  </InputMask>
                  <InputMask mask="9999" inputMode="numeric" onChange={(e) => setCardExpYear(e.target.value)}>
                    {(inputProps: any) => (
                      <input {...inputProps} placeholder="YY" type="text" className="form-control" autoComplete="cc-exp-year" />
                    )}
                  </InputMask>
                </div>
                <div className="mb-2">
                  <InputMask mask="9999" onChange={(e) => setCardCvc(e.target.value)}>
                    {(inputProps: any) => (
                      <input {...inputProps} placeholder="CVV" type="text" className="form-control" autoComplete="cc-csc" />
                    )}
                  </InputMask>
                </div>

                <div className="form-check disclam-check-box align-items-center mb-4">
                  <input
                    value="1"
                    id="save_card_info"
                    name="save_card_info"
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => setIsSave()}
                  />
                  <label className="form-check-label" htmlFor="save_card_info">
                    Do you want to save this credit card?
                  </label>
                </div>
              </div>
              <button
                className="red-btn w-100 text-uppercase d-flex justify-content-center align-items-center gap-2"
                onClick={(e) => makeCartTeamPayment()}
              >
                Pay now <i className="arrow-next-w" />
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={teamSuccessModal} size="xs" aria-labelledby="contained-modal-title-vcenter" centered>
        <ModalBody className={"text-center"}>
          <div className="modal-body modal-form-inner">
            <div className="payment-modal">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img src="/images/green-tick.svg" alt="" />
                <h2>Thank you for playing PFR!</h2>
                <p className="text-center">
                  You can now edit your team prior to entry closing. Please check your spam folder if you do not see
                  the confirmation email.
                </p>
                {transactionId ? (
                  <p>
                    Here is your confirmation number for your team: <strong>#{transactionId}</strong>
                  </p>
                ) : null}
                <p>Increase your chances!</p>
              </div>
              <button
                onClick={(e) => completeAction()}
                className="red-btn w-100 text-uppercase d-flex justify-content-center align-items-center gap-2"
              >
                Done <i className="arrow-next-w" />
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={TeamLoader} size="xs" aria-labelledby="contained-modal-title-vcenter" centered>
        <ModalBody className={"text-center"}>
          <div className="finding-team-modal">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src="/images/loader_customize.gif" alt="" />
              <h2 className="text-center">
                CREATING YOUR TEAM <br />
                FOR YOU...
              </h2>
              <p className="text-center">
                Almost there! Please wait a minute while we processing your team create process.
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={payLaterModal} size="xs" aria-labelledby="contained-modal-title-vcenter" centered>
        <ModalBody className={"text-center"}>
          <div className="payment-modal">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <img src="/images/alert-octagon.svg" alt="" />
              <h2>Having troubles?</h2>
              <p className="text-center mb-0 mt-3">
                You can also save the team and the office can contact you. Please check with your credit card company
                to make sure this payment can go through if you're still having trouble call the office at{" "}
                <a href="tel:+18447376336" className="red-link whitespace-nowrap">
                  +1 (844) 737 6336
                </a>
              </p>
            </div>
            <div>
              <button
                className="black-btn w-100 text-uppercase d-flex justify-content-center align-items-center gap-2"
                onClick={(e) => saveCartPayLater(1)}
              >
                Save & Office will contact you <i className="arrow-next-w" />
              </button>
            </div>
            <p
              className="text-center mb-0 mt-4 mb-0"
            >
              Or save the team and pay later.
            </p>
            <div>
              <button
                className="black-btn w-100 text-uppercase d-flex justify-content-center align-items-center gap-2 mt-1"
                onClick={(e) => saveCartPayLater(2)}
              >
                SAVE & PAY LATER <i className="arrow-next-w" />
              </button>
            </div>
            <p className="text-center mb-0 mt-4 mb-0">
              Sometimes the credit card company denies the transaction. Would you like to try another card?
            </p>
            <div className="d-flex">
              <button
                className="red-btn w-100 text-uppercase d-flex justify-content-center align-items-center gap-2 mt-1"
                onClick={() => { setPayLaterModal(false); setTeamLoader(false) }}
              >
                TRY AGAIN <i className="arrow-next-w" />
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
